@font-face {
  font-family: "NewYorkExtraLarge-Bold";
  src: local("NewYorkExtraLarge-Bold"), url(./fonts/NewYorkExtraLarge-Bold.otf);
}

@font-face {
  font-family: "NewYorkExtraLarge-Regular";
  src: local("NewYorkExtraLarge-Regular"),
    url(./fonts/NewYorkExtraLarge-Regular.otf);
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "NewYorkExtraLarge-Bold";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
